const title = "Trade master";
export const APP_VERSION = "1.0.4";
export const MERCURE_HUB_URL =
    "https://mercure.trademaster.in.ua/.well-known/mercure?topic=test-test-topic";

const loader = {
    // no more blinking in your app
    delay: 300, // if your asynchronous process is finished during 300 milliseconds you will not see the loader at all
    minimumLoading: 700, // but if it appears, it will stay for at least 700 milliseconds
};

const defaultMetaTags = {
    image: "/cover.png",
    description: "React app",
};

export { defaultMetaTags, loader, title };
